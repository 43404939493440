import { Box, Typography } from "@mui/material"

function WixOrderSummary({
    promisedTime, 
    customerName, 
    customerPhoneNumber, 
    deliveryAddress, 
    deliveryLocationDetails, 
    tip, 
    total, 
    formatE164PhoneNumber
}){
    return(
        <>
            <Box sx={{mb: 2}}>
                <Typography>
                    <span style={{fontWeight: "bold"}}>Promised Time:</span> {promisedTime}
                </Typography>
            </Box>
            
            <Box sx={{ mb: 2 }}>
                <Typography variant="h5" component="div" fontWeight={"bold"}>
                    {customerName}
                </Typography>
                <Typography>
                    <a href={`tel:${customerPhoneNumber}`}>{formatE164PhoneNumber(customerPhoneNumber)}</a>
                </Typography>
            </Box>

            <Typography variant="h5" fontWeight={"bold"}>
                Delivery Address:
            </Typography>
            <Box sx={{mb: 2}}>
                <Typography>
                    {deliveryAddress}
                </Typography>
                {deliveryLocationDetails != "" ? <Typography>{deliveryLocationDetails}</Typography> : <></>}
            </Box>

            <Box sx={{mb: 2}}>
                <Typography variant="h6">
                    <span style={{fontWeight: "bold"}}>Tip:</span> ${tip == "" ? 0 : tip}
                </Typography>
                <Typography variant="h6">
                    <span style={{fontWeight: "bold"}}>Total:</span> ${total}
                </Typography>
            </Box>
        </>
    )
}

export default WixOrderSummary