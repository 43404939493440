import { Box, Button, Typography } from "@mui/material"

function DoordashDeliverySummary({
    deliveryStatus,
    pickupEstimatedTime,
    dropOffEstimatedTime,
    supportReferenceNumber,
    driverName,
    driverPickupPhoneNumber,
    deliveryFee,
    trackingUrl,
    formatE164PhoneNumber
}){
    return(
        <Box>
            <Typography variant="h6">
                <span style={{fontWeight: "bold"}}>Delivery Status:</span> {deliveryStatus}
            </Typography>
            <Typography variant="h6">
                <span style={{fontWeight: "bold"}}>Estimated Pickup:</span> {pickupEstimatedTime}
            </Typography>
            <Typography variant="h6">
                <span style={{fontWeight: "bold"}}>Estimated Dropoff:</span> {dropOffEstimatedTime}
            </Typography>
            <Typography variant="h6">
                <span style={{fontWeight: "bold"}}>Doordash Ref#:</span> {supportReferenceNumber}
            </Typography>
            <Typography variant="h6">
                <span style={{fontWeight: "bold"}}>Driver Name:</span> {driverName}
            </Typography>
            {
                driverPickupPhoneNumber ?
                <Typography variant="h6">
                    <span style={{fontWeight: "bold"}}>Driver Phone#:</span> <a href={`tel:${driverPickupPhoneNumber}`}>{formatE164PhoneNumber(driverPickupPhoneNumber)}</a>
                </Typography>
                :
                <></>
            }
            <Typography variant="h6">
                <span style={{fontWeight: "bold"}}>Delivery Fee:</span> ${deliveryFee}
            </Typography>
            <Box textAlign={"center"} sx={{mt: 3}}>
                <Button variant="contained">
                    <a 
                        href={trackingUrl} 
                        target='_blank' 
                        style={{textDecoration: "none", color: "white", fontWeight: "bold"}}
                    >
                        Track Driver
                    </a>
                </Button>
            </Box>
        </Box>
    )
}

export default DoordashDeliverySummary