import { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import DeliveryOrder from '../components/Deliveries/DeliveryOrder';
import LinearProgress from '@mui/material/LinearProgress';

function Deliveries() { 
    const [orders, setOrders] = useState(null)

    useEffect(() => {
        const utcNow = new Date();
        // Convert UTC time to PST (UTC-8)
        const pstTime = new Date(utcNow.getTime() - 8 * 60 * 60 * 1000);
        // Set the time to "00:00:00"
        pstTime.setUTCHours(0, 0, 0, 0);
        // Format the PST time in ISO format
        let pstIsoFormat = pstTime.toISOString();
        fetch(`/api/wix/deliveries/gte:${pstIsoFormat}`, {
            method: "GET",
            headers:{
                "Authorization": `Basic ${process.env.REACT_APP_AUTH_TOKEN}`
            }
        })
        .then(response => response.json())
        .then(data => {
            if(data.message){
                alert(data.message)
            }else{
                setOrders(data["orders"])
            }
        })
    }, [])

    function refreshPage(){
        window.location.reload()
    }


    return(
        <div>
            <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                <Typography variant="h3" textAlign={"center"} padding={"8px"}>
                    Delivery Orders
                </Typography>
                <Button onClick={() => refreshPage()} variant="contained" style={{width: "250px"}}>Refresh</Button>
            </Box>
            {
                orders ? 
                    <Box display={"flex"} flexWrap={"wrap"} margin={"32px"} justifyContent={"center"} gap={"32px"}>
                        {
                            orders.map((o, i) => (
                                <DeliveryOrder key={i} order={o}/>
                            ))
                        }
                        {
                            orders.length == 0 ? <Typography>No Active Orders</Typography> : <></>
                        }
                    </Box>
                :
                <LinearProgress />
            }
        </div>
    )
}

export default Deliveries