import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OrderAccordianHeader from './OrderAccordianHeader';
import LinearProgress from '@mui/material/LinearProgress';
import WixOrderSummary from './WixOrderSummary';
import RequestDriverButton from './RequestDriverButton';
import DeliveryRequestModal from './DeliveryRequestModal';
import RequestSnackbarAlert from './RequestSnackbarAlert';
import DoordashDeliverySummary from './DoordashDeliverySummary';
import Chip from '@mui/material/Chip';



function DeliveryOrder({order}){
    const [loadingDriverRequest, setLoadingDriverRequest] = useState(false)
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const [modalOpen, setModalOpen] = useState(false)
    const [customerName, setCustomerName] = useState("")
    const [deliveryAddressInfo, setDeliveryAddressInfo] = useState("")
    const [deliveryLocationDetails, setDeliveryLocationDetails] = useState("")
    const [promisedTime, setPromisedTime] = useState("")
    const [driverRequested, setDriverRequested] = useState(false)
    const [items, setItems] = useState([])

    const [deliveryStatus, setDeliveryStatus] = useState(null)
    const [pickupEstimatedTime, setPickupEstimatedTime] = useState("")
    const [dropOffEstimatedTime, setDropoffEstimatedTime] = useState("")
    const [supportReferenceNumber, setSupportReferenceNumber] = useState("")
    const [driverName, setDriverName] = useState("")
    const [driverPickupPhoneNumber, setDriverPickupPhoneNumber] = useState("")
    const [deliveryFee, setDeliveryFee] = useState(0.0)
    const [trackingUrl, setTrackingUrl] = useState("")

    useEffect(() => {
        let address = order.fulfillment.deliveryDetails.address
        setCustomerName(`${order.customer.firstName} ${order.customer.lastName}`)
        setDeliveryAddressInfo(address)
        getDeliveryDetails(address)
        setPromisedTime(formatDateTime(order.fulfillment.promisedTime))
        checkIfDriverRequested(order.id)
        setItems(formatLineItems(order.lineItems))
    }, [order])

    function formatLineItems(items){
        let formattedItems = []
        items.map(item => {
            formattedItems.push({
                name: item.catalogReference.catalogItemName,
                quantity: item.quantity,
                price: parseFloat(item.price) * 100
            })
        })
        return formattedItems
    }

    function getDeliveryDetails(address){
        if (address.apt != "" && address.apt != null){
            let deliveryDetails = ""
            deliveryDetails += `Apt:${address.apt}`
            if (address.floor != "" && address.floor != null){
                deliveryDetails += `, Floor:${address.floor}`
                if (address.entrance != "" && address.entrance != null){
                    deliveryDetails += `, Entrance:${address.entrance}`
                }
            }
            setDeliveryLocationDetails(deliveryDetails)
        }
    }

    function formatDateTime(datetime){
        const dateObject = new Date(datetime);
        const pstOptions = { timeZone: 'America/Los_Angeles' };
        const pstDateString = dateObject.toLocaleString('en-US', pstOptions);
        return pstDateString
    }

    const formatE164PhoneNumber = (phoneNumber) => {
        // Remove any non-digit characters from the phone number
        const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
        // Extract country code and the rest of the number
        const countryCode = cleanedPhoneNumber.slice(1, 4);
        const restOfNumber = cleanedPhoneNumber.slice(4);
        // Format the phone number with parentheses and dashes
        const formattedNumber = `(${countryCode}) ${restOfNumber.slice(0, 3)}-${restOfNumber.slice(3, 7)}`;

        return formattedNumber;
    };

    function checkIfDriverRequested(id){
        fetch(`/api/doordash/delivery/${id}`, {
            method: "GET",
            headers:{
                "Authorization": `Basic ${process.env.REACT_APP_AUTH_TOKEN}`
            }
        })
        .then(response => response.json())
        .then(data => {
            if(data.code || data.error){
                setDriverRequested(false)
                setDeliveryStatus("")
            }else{
                setDriverRequested(true)
                setDeliveryStatus(data["delivery_status"].split("_").join(" "))
                setPickupEstimatedTime(formatDateTime(data["pickup_time_estimated"]))
                setDropoffEstimatedTime(formatDateTime(data["dropoff_time_estimated"]))
                setSupportReferenceNumber(data["support_reference"])
                setDriverName(data["dasher_name"])
                setDriverPickupPhoneNumber(data["dasher_pickup_phone_number"])
                setDeliveryFee(data["fee"]/100)
                setTrackingUrl(data["tracking_url"])
            }
        })
    }

    function scheduleDriver(){
        setLoadingDriverRequest(true)
        let deliveryBody = {
            "external_delivery_id": order.id,
            "dropoff_contact_given_name": customerName,
            "pickup_reference_tag": customerName,
            "pickup_address": "10920 Wilshire Blvd, Suite 110 Los Angeles, CA 90024",
            "pickup_instructions": "15 minute free parking at the back of the building on Ashton Ave (Restaurant is located in the lobby)",
            "pickup_business_name": "Trimana Grill Ucla",
            "pickup_phone_number": "+13102082946",
            "dropoff_address": deliveryAddressInfo.formatted,
            "dropoff_phone_number": order.customer.phone,
            "dropoff_instructions": deliveryAddressInfo.comment == null || deliveryAddressInfo == "" ? deliveryLocationDetails : deliveryLocationDetails + "/" + deliveryAddressInfo.comment,
            "items": items,
            "tip": order.totals.tip == "" || !order.totals.tip ? 0 : parseFloat(order.totals.tip) * 100,
            "contactless_dropoff": order.fulfillment.deliveryDetails.dropOffInfo.contactless,
            "order_value": parseFloat(order.totals.subtotal) * 100,
        }
        fetch('/api/doordash/delivery', {
            method: "POST",
            headers:{
                "Authorization": `Basic ${process.env.REACT_APP_AUTH_TOKEN}`
            },
            body: JSON.stringify(deliveryBody)
        })
        .then(response => response.json())
        .then(data => {
            if(data.code || data.error){
                setDriverRequested(false)
                openAlert({type: "error", message: data.message})
                setLoadingDriverRequest(false)
            }else{
                openAlert({type: "success", message: "Doordash driver has been requested"})
                setDeliveryStatus(data["delivery_status"])
                setPickupEstimatedTime(formatDateTime(data["pickup_time_estimated"]))
                setDropoffEstimatedTime(formatDateTime(data["dropoff_time_estimated"]))
                setSupportReferenceNumber(data["support_reference"])
                setDeliveryFee(data["fee"]/100)
                setTrackingUrl(data["tracking_url"])
                setLoadingDriverRequest(false)
                setModalOpen(false)
                setDriverRequested(true)
            }
        })
    }

    function openAlert(aInfo){
        setAlertOpen(true)
        setAlertInfo({type: aInfo.type, message: aInfo.message})
    }

    function handleAlertClose(){
        setAlertOpen(false)
        setAlertInfo({})
    }

    function handleModalClose(){
        setModalOpen(false)
    }

    return(
        <Box>
            <Accordion style={{width: "375px"}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
                        <Typography variant="h5" fontWeight={"bold"}>
                            Order#: {order.id}
                        </Typography>
                        {deliveryStatus != null ? 
                            <OrderAccordianHeader deliveryStatus={deliveryStatus.toUpperCase()} wixOrderStatus={order.status}/> 
                            : 
                            <LinearProgress/>
                        }
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Card sx={{ minWidth: 350, minHeight: 350 }}>
                        <CardContent>
                            <WixOrderSummary
                                promisedTime={promisedTime}
                                customerName={customerName}
                                customerPhoneNumber={order.customer.phone}
                                deliveryAddress={deliveryAddressInfo.formatted}
                                deliveryLocationDetails={deliveryLocationDetails}
                                tip={order.totals.tip}
                                total={order.totals.total}
                                formatE164PhoneNumber={formatE164PhoneNumber}
                            />

                            <RequestDriverButton
                                driverRequested={driverRequested}
                                setModalOpen={setModalOpen}
                                wixOrderStatus={order.status}
                            />
                            
                            {driverRequested ? 
                                <DoordashDeliverySummary
                                    deliveryStatus={deliveryStatus}
                                    pickupEstimatedTime={pickupEstimatedTime}
                                    dropOffEstimatedTime={dropOffEstimatedTime}
                                    supportReferenceNumber={supportReferenceNumber}
                                    driverName={driverName}
                                    driverPickupPhoneNumber={driverPickupPhoneNumber}
                                    deliveryFee={deliveryFee}
                                    trackingUrl={trackingUrl}
                                    formatE164PhoneNumber={formatE164PhoneNumber}
                                /> : <></>
                            }
                        </CardContent>
                    </Card>
                </AccordionDetails>
            </Accordion>
            <RequestSnackbarAlert
                alertOpen={alertOpen}
                handleAlertClose={handleAlertClose}
                alertInfo={alertInfo}
            />
            <DeliveryRequestModal
                modalOpen={modalOpen}
                handleModalClose={handleModalClose}
                id={order.id}
                loadingDriverRequest={loadingDriverRequest}
                scheduleDriver={scheduleDriver}
            />
        </Box>
    )
}

export default DeliveryOrder

