import { Box, Button, Typography } from "@mui/material";


function RequestDriverButton({driverRequested, setModalOpen, wixOrderStatus}){
    return(
        <>
            {!driverRequested ? 
                (wixOrderStatus == "NEW" || wixOrderStatus == "ACCEPTED") ?
                <Box textAlign={"center"}>
                    <Button onClick={() => setModalOpen(true)} variant="contained">Request Driver</Button>
                </Box> : <Typography color={"primary"} textAlign={"center"} fontWeight={"bold"}>WIX ORDER {wixOrderStatus}</Typography>
                :
                <Box 
                    style={{
                        width:"100%", 
                        height:"2px",
                        backgroundColor: "black"
                    }}
                >
                </Box>
            }
        </>
    )
}

export default RequestDriverButton