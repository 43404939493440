import Chip from '@mui/material/Chip';

function OrderAccordianHeader({deliveryStatus, wixOrderStatus}){
    return(
        <>
            {deliveryStatus != "" ? 
                <Chip 
                    label={deliveryStatus} 
                    color="success" 
                    variant={deliveryStatus == "DELIVERED" ? "contained" : "outlined"} 
                />
                :
                (wixOrderStatus == "NEW" || wixOrderStatus == "ACCEPTED") ? 
                    <Chip label="Driver Not Requested" color="primary" variant="outlined" /> : <></>
            }
        </>
    )
}

export default OrderAccordianHeader