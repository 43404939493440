import { Modal, Box, Typography, CircularProgress, Button } from "@mui/material"


function DeliveryRequestModal({
    modalOpen, 
    handleModalClose, 
    id, 
    loadingDriverRequest, 
    scheduleDriver
}){
    return(
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            >
            <Box sx={modalStyle}>
                <Typography variant="h6" textAlign={"center"}>
                    Are you sure you would like to request a driver for order#{id}?
                </Typography>
                <Box display={"flex"} gap={"16px"} justifyContent={"center"} sx={{mt: 3}}>
                    {
                        loadingDriverRequest ? 
                            <CircularProgress />
                        :
                        <>
                            <Button onClick={scheduleDriver} variant="contained">Yes</Button>
                            <Button onClick={handleModalClose} variant="contained">Cancel</Button>
                        </>
                    }
                </Box>
            </Box>
        </Modal>
    )
}

export default DeliveryRequestModal

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};