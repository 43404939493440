import { Snackbar, Alert } from "@mui/material"


function RequestSnackbarAlert({alertOpen, handleAlertClose, alertInfo}){
    return(
        <>
            <Snackbar 
                open={alertOpen} 
                autoHideDuration={6000} 
                onClose={handleAlertClose} 
                anchorOrigin={{vertical: "top", horizontal: "left"}}
            >
                <Alert onClose={handleAlertClose} severity={alertInfo.type} sx={{ width: '100%' }}>
                    {alertInfo.message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default RequestSnackbarAlert