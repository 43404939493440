import React, { useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import { Button, Typography, Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import {useSignIn, useIsAuthenticated} from "react-auth-kit"

function Login() {
    const navigate = useNavigate()
    const signIn = useSignIn()
    const isAuthenticated = useIsAuthenticated()

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        const auth = isAuthenticated();
        return auth ? navigate("/deliveries") : navigate("/")
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();
        let account = {
            "username": username,
            "password": password
        }
        fetch('/api/auth/login', {
            method: "POST",
            headers:{
                "Authorization": `Basic ${process.env.REACT_APP_AUTH_TOKEN}`
            },
            body: JSON.stringify(account)
        })
        .then(response => response.json())
        .then(data => {
            if(data["token"]){
                signIn({
                    token: data["token"],
                    expiresIn: 180,
                    tokenType: "Bearer",
                    authState: {username: username}
                })
                navigate("/deliveries")
            }else{
                alert(`Error: ${data["result"]}`)
            }
        })
        .catch(error => console.error(error));
    }

    return (
        <>  
            <Box style={{background: "white", fontFamily: "Ubuntu-Bold, sans-serif",}} height={"100vh"} display={"flex"} flexWrap={"wrap"} justifyContent={"center"} alignItems={"center"}>
                <Box width={"368px"} textAlign={"center"} padding={"16px"}>
                    <Typography style={headingStyle} variant="h5" component="h5">Trimana Delivery Dashboard</Typography>
                    <OutlinedInput
                        placeholder='Username'
                        style={inputStyle}
                        startAdornment={
                            <InputAdornment position="start">
                                <PersonIcon style={iconStyle}/>
                            </InputAdornment>
                        }
                        value={username} 
                        onChange={event => setUsername(event.target.value)}
                    />
                    <OutlinedInput
                        placeholder='Password'
                        type='password'
                        style={inputStyle}
                        startAdornment={
                            <InputAdornment position="start">
                                <LockIcon style={iconStyle}/>
                            </InputAdornment>
                        }
                        value={password} 
                        onChange={event => setPassword(event.target.value)}
                    />
                    <Button style={buttonStyle} onClick={handleSubmit}>Login</Button>
                </Box>
            </Box>
    </>
    );
};

export default Login;

const inputStyle = {
    fontSize: "15px",
    fontWeight: "bold",
    color: "#333",
    width: "100%",
    background: "#E6E6E6",
    height: "62px",
    margin: "8px 0px",
    border: "1px solid #827ffe"
}

const buttonStyle = {
    marginTop: "16px",
    borderRadius: '3px',
    backgroundColor: '#827ffe',
    border: 'none',
    color: 'white',
    fontWeight: "bold",
    fontSize: '16px',
    width: '100%',
    height: "62px",
    margin: "36px 0px"
};

const headingStyle = {
    fontWeight:'bold',
    textAlign:'center',
    color: "#403866",
    fontSize: "30px",
    padding: "36px 0px"
}

const iconStyle = {
    color: "#827ffe"
}